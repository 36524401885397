/**
 * View Gas Data
 *
 * @module gasdata
 * @author: klj@dgc.dk
 */
import Typography from "@mui/material/Typography"
import { ceil, max } from "lodash"
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"
import { GasFlowMndRec, useGasflowMndQuery } from "../../services/pglocal"
import { secColorHex } from "../../theme"

function formatTick(value: any) {
  return typeof value === "string" && value.substring(4) === "-01"
    ? value.substring(0, 4)
    : value
}

function gasAreaChart(data: GasFlowMndRec[]) {
  const maxY = max(data.map((v) => v.dkforbrug))
  const maxYfit = ceil(maxY!, -3)
  return (
    <ResponsiveContainer width={"90%"} aspect={2} minWidth={"20rem"}>
      <AreaChart data={data} margin={{ top: 30 }}>
        <defs>
          <linearGradient id="color_dkforbrug" x1="0" y1="0" x2="0" y2="1">
            <stop offset="30%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="color_biongasprod" x1="0" y1="0" x2="0" y2="1">
            <stop offset="50%" stopColor={secColorHex} stopOpacity={0.8} />
            <stop offset="100%" stopColor={secColorHex} stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="gasmonth"
          tick={{ fontSize: "0.8rem" }}
          interval={11}
          tickFormatter={formatTick}
        />
        <YAxis tick={{ fontSize: "0.8rem" }} domain={[0, maxYfit]} tickCount={6} />
        <CartesianGrid />
        <Legend align="right" verticalAlign="top" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="dkforbrug"
          name="Samlet dansk gasforbrug [GWh/måned]"
          stroke="#8884d8"
          fillOpacity={1}
          fill="url(#color_dkforbrug)"
        />
        <Area
          type="monotone"
          dataKey="bionaturgas"
          name="Bionaturgasproduktion [GWh/måned]"
          stroke={secColorHex}
          fillOpacity={1}
          fill="url(#color_biongasprod)"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export function GasAmountChart() {
  const { data, error, isLoading } = useGasflowMndQuery()
  if (error)
    return <Typography component="pre">{JSON.stringify(error, null, 2)}</Typography>
  if (isLoading) return <Typography paragraph>Loading...</Typography>
  if (typeof data === "undefined")
    return <Typography paragraph>Unexpected state!</Typography>
  return gasAreaChart(data)
}
