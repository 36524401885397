import { createRoot } from "react-dom/client"
import "@fontsource/roboto"
import "./index.css"
import App from "./App"
import { store } from "./store"
import { Provider } from "react-redux"
// import reportWebVitals from './reportWebVitals';

// Af hensyn til ældre browsere
import "core-js"
import "whatwg-fetch"

const container = document.getElementById("root")!
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
