/**
 * View Gas Data
 *
 * @module gasdata
 * @author: klj@dgc.dk
 */
import Typography from "@mui/material/Typography"
import { round } from "lodash"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"
import { EmiFlowMndRec, useEmiFlowMndQuery } from "../../services/pglocal"
import { secColorHex } from "../../theme"

function barChart(data: EmiFlowMndRec[]) {
  const cdata = data.slice(-12).map(({ gasmonth, c_emifakt }) => ({
    gasmonth,
    c_emifakt: round(c_emifakt, 1)
  }))
  return (
    <ResponsiveContainer width={"90%"} aspect={2} minWidth={"20rem"}>
      <BarChart data={cdata}>
        <defs>
          <linearGradient id="color_graph" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={secColorHex} stopOpacity={0.8} />
            <stop offset="95%" stopColor={secColorHex} stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis dataKey="gasmonth" tick={{ fontSize: "0.8rem" }} interval={0} />
        <YAxis tick={{ fontSize: "0.8rem" }} />
        <CartesianGrid />
        <Legend align="right" verticalAlign="top" />
        <Tooltip />
        <Bar
          type="monotone"
          dataKey="c_emifakt"
          stroke={secColorHex}
          fillOpacity={1}
          fill="url(#color_graph)"
          name="CO₂-emissionsfaktor [kg/GJ]"
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export function EmiLastOneYear() {
  const { data, error, isLoading } = useEmiFlowMndQuery()
  if (error)
    return <Typography component="pre">{JSON.stringify(error, null, 2)}</Typography>
  if (isLoading) return <Typography paragraph>Loading...</Typography>
  if (typeof data === "undefined")
    return <Typography paragraph>Unexpected state!</Typography>
  return barChart(data)
}
