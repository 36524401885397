import { createTheme, ThemeOptions } from "@mui/material/styles"

export const primColorHex = "#0e4744"
export const secColorHex = "#2ea149"

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: primColorHex
    },
    secondary: {
      main: secColorHex
    },
    info: {
      main: secColorHex
    },
    text: {
      primary: primColorHex,
      secondary: secColorHex
    }
  },
  typography: {
    h1: {
      fontSize: 48,
      fontWeight: 400,
      lineHeight: 1.15,
      color: primColorHex,
      marginBottom: "2rem"
    },
    h2: {
      fontSize: 48,
      fontWeight: 400,
      lineHeight: 1.15,
      color: secColorHex
    }
  }
}

export default createTheme(themeOptions)
