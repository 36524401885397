/**
 * View Gas Data
 *
 * @module gasdata
 * @author: klj@dgc.dk
 */
import Typography from "@mui/material/Typography"
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"
import { GasFlowMndRec, useGasflowMndQuery } from "../../services/pglocal"
import { secColorHex } from "../../theme"

function formatTick(value: any) {
  return typeof value === "string" && value.substring(4) === "-01"
    ? value.substring(0, 4)
    : value
}

function areaChart(data: GasFlowMndRec[]) {
  return (
    <ResponsiveContainer width={"90%"} aspect={2} minWidth={"20rem"}>
      <AreaChart data={data} margin={{ top: 30 }}>
        <defs>
          <linearGradient id="color_graph" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={secColorHex} stopOpacity={0.8} />
            <stop offset="95%" stopColor={secColorHex} stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="gasmonth"
          tick={{ fontSize: "0.8rem" }}
          interval={11}
          tickFormatter={formatTick}
        />
        <YAxis tick={{ fontSize: "0.8rem" }} tickCount={6} />
        <CartesianGrid />
        <Legend align="right" verticalAlign="top"  />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="andelpct"
          stroke={secColorHex}
          fillOpacity={1}
          fill="url(#color_graph)"
          name="Bionaturgasandel [%]"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export function RelBioNgasChart() {
  const { data, error, isLoading } = useGasflowMndQuery()
  if (error)
    return <Typography component="pre">{JSON.stringify(error, null, 2)}</Typography>
  if (isLoading) return <Typography paragraph>Loading...</Typography>
  if (typeof data === "undefined")
    return <Typography paragraph>Unexpected state!</Typography>
  return areaChart(data)
}
