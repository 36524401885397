/**
 * View Gas Data
 *
 * @module gasdata
 * @author: klj@dgc.dk
 */
import Typography from "@mui/material/Typography"
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"
import { AndelDagRec, useAndelDagQuery } from "../../services/pglocal"
import { secColorHex } from "../../theme"

function formatTick(value: any) {
  return typeof value === "string" && value.substring(4) === "-01-01"
    ? value.substring(0, 4)
    : value
}

function barChart(data: AndelDagRec[]) {
  // const dagData = data
  //   .filter(({ dag }) => dag.slice(-5) !== "02-29")
  //   .map(({ dag, dkforbrug, biongasprod }) => ({
  //     dag,
  //     biongaspct: round((100 * biongasprod) / dkforbrug, 1)
  //   }))
  return (
    <ResponsiveContainer width={"90%"} aspect={2} minWidth={"20rem"}>
      <AreaChart data={data} margin={{ top: 30 }}>
        <defs>
          <linearGradient id="color_graph" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={secColorHex} stopOpacity={0.8} />
            <stop offset="95%" stopColor={secColorHex} stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="gasday"
          interval={364}
          tick={{ fontSize: "0.8rem" }}
          tickFormatter={formatTick}
        />
        <YAxis tick={{ fontSize: "0.8rem" }} tickCount={6} />
        <CartesianGrid />
        <Legend align="right" verticalAlign="top" />
        <Tooltip />
        <Area
          dataKey="andelpct"
          stroke={secColorHex}
          strokeOpacity={0.7}
          fill="url(#color_graph)"
          fillOpacity={1}
          name="Bionaturgasandel [%]"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export function DagBioNgasChart() {
  const { data, error, isLoading } = useAndelDagQuery()
  if (error)
    return <Typography component="pre">{JSON.stringify(error, null, 2)}</Typography>
  if (isLoading) return <Typography paragraph>Loading...</Typography>
  if (typeof data === "undefined")
    return <Typography paragraph>Unexpected state!</Typography>
  return barChart(data)
}
