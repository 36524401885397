/**
 * View Gas Data
 *
 * @module gasdata
 * @author: klj@dgc.dk
 */
import Typography from "@mui/material/Typography"
import { groupBy, mapValues, round, sumBy, toPairs } from "lodash"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"
import { EmiFlowMndRec, useEmiFlowMndQuery } from "../../services/pglocal"
import { secColorHex } from "../../theme"

type Emissions = { aar: string; emifakt: number }[]

function calcYearlyEmissions(data: EmiFlowMndRec[]): Emissions {
  const curYear = String(new Date().getFullYear())
  // Emissionsdata for indeværende år fjernes, da de ikke indeholder et helt år
  const dataF = data.filter((v) => v.gasmonth.substring(0, 4) !== curYear)
  // Nyt array med relevante data og udregnet månedlig CO₂-udledning
  const co2data = dataF.map(({ gasmonth, denmark, biogas, emifakt }) => ({
    gasmonth,
    dkforbrug: -denmark,
    co2: (-denmark - biogas) * emifakt // CO2 fra fossilt naturgas
  }))
  // Gruppér efter årstal
  const co2grp = groupBy(co2data, ({ gasmonth }) => gasmonth.substring(0, 4))
  const yearEmiSums = mapValues(co2grp, (a) => ({
    co2: sumBy(a, (v) => v.co2),
    dkforbrug: sumBy(a, (v) => v.dkforbrug)
  }))
  const yearlyEmissions = toPairs(yearEmiSums).map(
    ([aar, { co2, dkforbrug }]) => ({
      aar,
      emifakt: round(co2 / dkforbrug, 1)
    })
  )
  return yearlyEmissions
}

function barChart(data: EmiFlowMndRec[]) {
  const yearlyEmissions = calcYearlyEmissions(data)
  return (
    <ResponsiveContainer width={"90%"} aspect={2} minWidth={"20rem"}>
      <BarChart data={yearlyEmissions}>
        <defs>
          <linearGradient id="color_graph" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={secColorHex} stopOpacity={0.8} />
            <stop offset="95%" stopColor={secColorHex} stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis dataKey="aar" tick={{ fontSize: "0.8rem" }} interval={0} />
        <YAxis tick={{ fontSize: "0.8rem" }} />
        <CartesianGrid />
        <Legend align="right" verticalAlign="top" />
        <Tooltip />
        <Bar
          type="monotone"
          dataKey="emifakt"
          stroke={secColorHex}
          fillOpacity={1}
          fill="url(#color_graph)"
          name="CO₂-emissionsfaktor [kg/GJ]"
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export function YearlyEmissionsChart() {
  const { data, error, isLoading } = useEmiFlowMndQuery()
  if (error)
    return <Typography component="pre">{JSON.stringify(error, null, 2)}</Typography>
  if (isLoading) return <Typography paragraph>Loading...</Typography>
  if (typeof data === "undefined")
    return <Typography paragraph>Unexpected state!</Typography>
  return barChart(data)
}
