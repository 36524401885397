/**
 * View Gas Data
 *
 * @module gasdata
 * @author: klj@dgc.dk
 */
import Typography from "@mui/material/Typography"
import { groupBy, map, mapValues, round, sumBy, toPairs } from "lodash"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"
import { GasFlowMndRec, useGasflowMndQuery } from "../../services/pglocal"
import { secColorHex } from "../../theme"

function barChart(data: GasFlowMndRec[]) {
  const curYear = String(new Date().getFullYear())
  const dataF = data.filter(({ gasmonth }) => gasmonth.substring(0, 4) !== curYear)
  const grpdata = groupBy(dataF, (v) => v.gasmonth.substring(0, 4))
  const aggrdata = mapValues(grpdata, (v) => ({
    dkforbrug: sumBy(v, (v2) => v2.dkforbrug),
    biongasprod: sumBy(v, (v2) => v2.bionaturgas)
  }))
  const yeardata = map(toPairs(aggrdata), ([aar, { dkforbrug, biongasprod }]) => ({
    aar,
    biongaspct: round((100 * biongasprod) / dkforbrug, 1)
  }))
  return (
    <ResponsiveContainer width={"90%"} aspect={2} minWidth={"20rem"}>
      <BarChart data={yeardata} margin={{ top: 30 }}>
        <defs>
          <linearGradient id="color_graph" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={secColorHex} stopOpacity={0.8} />
            <stop offset="95%" stopColor={secColorHex} stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis dataKey="aar" tick={{ fontSize: "0.8rem" }} />
        <YAxis tick={{ fontSize: "0.8rem" }} tickCount={6} />
        <CartesianGrid />
        <Legend align="right" verticalAlign="top" />
        <Tooltip />
        <Bar
          type="monotone"
          dataKey="biongaspct"
          stroke={secColorHex}
          fillOpacity={1}
          fill="url(#color_graph)"
          name="Bionaturgasandel [%]"
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export function YearBioNgasChart() {
  const { data, error, isLoading } = useGasflowMndQuery()
  if (error)
    return <Typography component="pre">{JSON.stringify(error, null, 2)}</Typography>
  if (isLoading) return <Typography paragraph>Loading...</Typography>
  if (typeof data === "undefined")
    return <Typography paragraph>Unexpected state!</Typography>
  return barChart(data)
}
