import { ThemeProvider } from "@mui/material/styles"
import { Box, Container, Typography } from "@mui/material"
import theme from "./theme"
import TopBar from "./TopBar"
import { RelBioNgasChart } from "./features/gasdata/RelBioNgasChart"
import { YearBioNgasChart } from "./features/gasdata/YearBioNgasChart"
import { GasAmountChart } from "./features/gasdata/AbsGasChart"
import { DagBioNgasChart } from "./features/gasdata/DagBioNgasChart"
import { EmiLastOneYear } from "./features/gasdata/EmiOneYear"
import { YearlyEmissionsChart } from "./features/gasdata/YearlyEmissionsChart"

export default function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <TopBar />
        <Container maxWidth="md">
          <Typography variant="h1">Hvor grøn er gassen</Typography>
          <Typography paragraph>
            Gassen i det danske gassystem oplever i disse år en markant forandring, idet
            mængden af biogas øges år for år. De følgende figurer viser, hvor stor en
            andel af biogas der er tilført det danske ledningsnet i forhold til det danske
            gasforbrug. Den øgede mængde biogas gør, at den udledte mængde CO₂ i forhold
            til energiforbrug falder.
          </Typography>
          <GasAmountChart />
          <Typography variant="subtitle2" sx={{ marginBottom: 5 }}>
            Samlet gasforbrug sammenlignet med bionaturgasproduktion på månedsbasis.
          </Typography>
          <Typography paragraph>
            Gasforbruget i Danmark varierer meget med årstiden, da en stor del anvendes
            til opvarmning. Bionaturgasandelen af forbruget topper derfor i
            sommermånederne, hvor det samlede forbrug er lavest.
          </Typography>
          <RelBioNgasChart />
          <Typography variant="subtitle2" sx={{ marginBottom: 5 }}>
            Bionaturgastilførsel i forhold til samlet forbrug på månedsbasis.
          </Typography>
          <Typography paragraph>
            Om sommeren er der dage, hvor næsten hele Danmarks gasforbrug dækkes af
            bionaturgas.
          </Typography>
          <DagBioNgasChart />
          <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
            Bionaturgastilførsel i forhold til samlet forbrug på dagsbasis.
          </Typography>
          <YearBioNgasChart />
          <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
            Bionaturgastilførsel i forhold til samlet forbrug på årsbasis.
          </Typography>
          <Typography paragraph>
            Bionaturgas regnes for at være CO₂-neutralt, og CO₂-emissionsfaktoren for
            bionaturgas er derfor 0. Da bionaturgasandelen af forbruget er størst i
            sommermånederne, bliver CO₂-emissionsfaktoren derfor også lavest i disse
            måneder.
          </Typography>
          <EmiLastOneYear />
          <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
            Månedlig CO₂-emissionsfaktor for gasnettet for det seneste år.
          </Typography>
          <Typography paragraph>
            Den stadigt stigende mængde bionaturgas i gasnettet medfører, at den årlige
            emissionsfaktor for gassen i ledningsnettet falder år for år.
          </Typography>
          <YearlyEmissionsChart />
          <Typography variant="subtitle2" sx={{ marginBottom: 3 }}>
            Årlig CO₂-emissionsfaktor for gasnettet.
          </Typography>
          <Typography paragraph>
            Datakilde:&nbsp;
            <a
              href="https://www.energidataservice.dk/groups/gas-quality"
              target="_blank"
              rel="noreferrer"
            >
              https://www.energidataservice.dk/groups/gas-quality
            </a>
          </Typography>
          <Typography paragraph>
            Kontaktperson: Per G. Kristensen (<a href="mailto:pgk@dgc.dk">pgk@dgc.dk</a>)
          </Typography>
        </Container>
      </Box>
    </ThemeProvider>
  )
}
